<template lang="pug">
.notification(
  :class="[type, show ? 'show' : 'hide']"
) {{ message }}
</template>

<script>
export default {
  name: 'notification',
  data: () => ({
    type: '',
    message: '',
    show: false,
  }),
  props: ['notify'],
  watch: {
    notify: function(data) {
      const _this = this;

      this.type = data.type;
      this.message = data.message;
      this.show = true

      setTimeout(function(){
        _this.show = false
        _this.type = ''
        _this.message = ''
      }, 6000)
    }
  }
}
</script>

<style lang="sass">
  @import '@/style/components/Notification.sass'
</style>